import PropTypes from 'prop-types';
import {useRouter} from 'next/router';

export default function CtaButton({className, href, title}) {
  const router = useRouter();

  /**
   * Modifies the provided href from Plasmic Studio based on the current URL's query parameters.
   * Specifically, it appends `externalPayment=true` to the href if the current URL contains this parameter (New payment Flow).
   *
   * @returns {string} - The modified href if conditions are met, or the original href.
   */
  const getModifiedHref = () => {
    if (process.env.GIT_BRANCH !== 'main' && router?.asPath) {
      const currentUrlParams = new URLSearchParams(router.asPath.split('?')[1]);
      if (currentUrlParams.get('externalPayment') === 'true') {
        return `${href}${href.includes('?') ? '&' : '?'}externalPayment=true`;
      }
    }
    return href;
  };

  return (
    <a className={className} href={getModifiedHref(href)}>
      {title}
    </a>
  );
}

CtaButton.defaultProps = {
  className: '',
  href: '',
  title: '',
};

CtaButton.propTypes = {
  // className imported from Plasmic Studio to manage Styling
  className: PropTypes.string,
  // set on Plasmic Studio to manage redirection (Set on Plasmic Studio)
  href: PropTypes.string,
  // title of the button (Set on Plasmic Studio)
  title: PropTypes.string,
};

export const PlasmicData = {
  name: 'Cta.Button',
  props: {
    href: {
      type: 'string',
      defaultValue: '/pricing/#start-now',
    },
    title: {
      type: 'string',
      defaultValue: 'Start Now',
    },
  },
  defaultStyles: {},
  importPath: './components/Subscription/CtaButton',
  isDefaultExport: true,
};
