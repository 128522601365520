// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import * as React from "react";

export const C1RWdyOnVipExbhYAfContext = React.createContext(
  "PLEASE_RENDER_INSIDE_PROVIDER"
);

export function useC1RWdyOnVipExbhYAf() {
  return React.useContext(C1RWdyOnVipExbhYAfContext);
}

export default C1RWdyOnVipExbhYAfContext;
/* prettier-ignore-end */
