// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import * as React from "react";

export const _121620241500122620240500Context = React.createContext(
  "PLEASE_RENDER_INSIDE_PROVIDER"
);

export function use_121620241500122620240500() {
  return React.useContext(_121620241500122620240500Context);
}

export default _121620241500122620240500Context;
/* prettier-ignore-end */
