// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import * as React from "react";

export const _010920251500021520250600Context = React.createContext(
  "PLEASE_RENDER_INSIDE_PROVIDER"
);

export function use_010920251500021520250600() {
  return React.useContext(_010920251500021520250600Context);
}

export default _010920251500021520250600Context;
/* prettier-ignore-end */
