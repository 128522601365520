// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import * as React from "react";

export const _122620240501010120250600Context = React.createContext(
  "PLEASE_RENDER_INSIDE_PROVIDER"
);

export function use_122620240501010120250600() {
  return React.useContext(_122620240501010120250600Context);
}

export default _122620240501010120250600Context;
/* prettier-ignore-end */
